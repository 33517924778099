//TODO Please complete [.env] file before filling the data below!!!

//? This is global meta tag config.
export const SiteMetaConfig = {
  site_language: "en", //? This is global site language.
  site_url: "http://localhost:8000", //? This is global site base URL. It supposed to be the domain with protocal.
  site_name: "Example Title", //? This is global site name. It will be showed beside every title. And it's the dafault title for Index Page.
  site_description: "This is a example description, if you see this please contact us", //? This is global site description. It will be showed for every page. And it's the dafault title for Index Page.
  site_image: "/assets/banner.jpg", //? This is global banner image. It is the dafault image that will be showed on every social media card.
  site_twitter_handle: "kylemathews", //? This is option to add twitter username. It will be used when sharing to twitter.
//  site_facebook_app_id: "", //? This is Facebook App ID and it is used to get data in Facebook insights.
}



//? This is used to override meta data for Index Page.
//! Writing anything here will override dafault data.
export const LandingMetaConfig = {
  seo_title: "Not A Example Title", //? This is used to override title for Index Page.
  seo_description: "This is a not a example description, if you see this please contact us", //? This is used to override description for Index Page.
  seo_image: "https://www.example.ie/og-image-03.jpg", //? This is used to override banner image for Index Page.
  
  //! IT IS BOOLEAN
//  seo_no_index: "", //? This is used to mark Index Page as NO INDEX for search engines.

  //! IT IS BOOLEAN
//  seo_no_follow: "", //? This is used to mark Index Page as NO FOLLOW for search engines.
}



//? This is for controlling which parts will be visible in Index Page.
export const LandingPageConfig = {
  //! IT IS BOOLEAN
  hero_section: "true", //? This is for controlling if Hero Section on Index Page will be showed.
  
  //! IT IS BOOLEAN
  video_section: "true", //? This is for controlling if Video Section on Index Page will be showed.
  
  //! IT IS BOOLEAN
  socialproof_section: "true", //? This is for controlling if Social Proof Section on Index Page will be showed.

  //! IT IS BOOLEAN
  benefits_section: "true", //? This is for controlling if Benefits Section on Index Page will be showed.

  //! IT IS BOOLEAN
  about_section: "true", //? This is for controlling if About Section on Index Page will be showed.

  //! IT IS BOOLEAN
  faq_section: "true", //? This is for controlling if Faq Section on Index Page will be showed.

  //! IT IS BOOLEAN
  calltoaction_section: "true", //? This is for controlling if Call To Action Section on Index Page will be showed.



  //! IT IS BOOLEAN
  header_option: "true", //? This is for controlling if Header on top of Index Page will be showed.

  //! IT IS BOOLEAN
  footer_option: "true", //? This is for controlling if Footer on bottom of Index Page will be showed.



  //! IT IS BOOLEAN
  counter_bar_option: "true", //? This is for controlling if Counter Bar on Index Page will be showed.

  //! IT IS BOOLEAN
  footer_bar_option: "true", //? This is for controlling if Footer Bar on Index Page will be showed.

  //! IT IS BOOLEAN
  //TODO: Only turn it off if Lead Form is never used in Index Page.
  modal_option: "true", //? This is for controlling if Modal with Lead Form will be rendered on Index Page.

  //! IT IS BOOLEAN
  modal_outside_close: "true", //? This is for controlling if clicking on outside of the Modal will close the Modal on Index Page.



  //! IT IS BOOLEAN
  //TODO: Only turn it on if something written in content.js file
//  popup_option: "", //? This is for controlling if PopUp on Index Page will be showed.



  //! IT IS BOOLEAN
  //TODO: Only turn it on if something written in animation.js file
//  animation_section: "", //? This is for controlling if Animation Section on Index Page will be showed.

  //! IT IS BOOLEAN
  //TODO: Only turn it on if something written in special.js file
//  special_section: "", //? This is for controlling if Special Section on Index Page will be showed.
}



//? This is for data in Feedback Page
export const FeedbackConfig = {
//  review_submission_link: "", //? This is for submitting only good reviews. Use Google/Facebook/Yelp review giving link.
}



//? This is for configuring different policy parts in Help Page.
export const HelpConfig = {
  //! IT IS BOOLEAN
  default_privacy_policy: "yes", //? This is for controlling if Default Privacy Policy on Help Page will be showed.

  //! IT IS BOOLEAN
  default_terms_and_conditions: "yes", //? This is for controlling if Default Terms And Conditions on Help Page will be showed.

  //! IT IS BOOLEAN
  default_disclaimer: "yes", //? This is for controlling if Default Disclaimer on Help Page will be showed.

  lawsuit_amount: "20.00", //? This is for setting highest lawsuit Amount in disclaimer.
  lawsuit_country: "Bangladesh", //? This is for setting lawsuit Country in disclaimer.
  lawsuit_city: "Dhaka", //? This is for setting lawsuit City in disclaimer.
  lawsuit_language: "Bangla", //? This is for setting lawsuit Language in disclaimer.
}



//? This is for adding Link in Header.
//! IT IS ARRAY
export const HeaderLinkData = [
  {
    navigation_link_label: "Home", //? This is for setting Link Label that will be showed in Header.

    //TODO: Don't fill this if "navigation_url_link" is filled.
    navigation_page_link: "/", //? This is for setting Page Link to the Link Label in Header.

    //TODO: Don't fill this if "navigation_page_link" is filled.
    navigation_url_link: "", //? This is for setting URL Link to the Link Label in Header.

    //! IT IS BOOLEAN
    navigation_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Header.
  },
/*  {
    navigation_link_label: "Tags", //? This is for setting Link Label that will be showed in Header.

    //TODO: Don't fill this if "navigation_url_link" is filled.
    navigation_page_link: "/blog/tags", //? This is for setting Page Link to the Link Label in Header.

    //TODO: Don't fill this if "navigation_page_link" is filled.
    navigation_url_link: "", //? This is for setting URL Link to the Link Label in Header.

    //! IT IS BOOLEAN
    navigation_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Header.
  },
  {
    navigation_link_label: "Blog", //? This is for setting Link Label that will be showed in Header.

    //TODO: Don't fill this if "navigation_url_link" is filled.
    navigation_page_link: "/blog/blog-page-1", //? This is for setting Page Link to the Link Label in Header.

    //TODO: Don't fill this if "navigation_page_link" is filled.
    navigation_url_link: "", //? This is for setting URL Link to the Link Label in Header.

    //! IT IS BOOLEAN
    navigation_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Header.
  }, */
  {
    navigation_link_label: "Help", //? This is for setting Link Label that will be showed in Header.

    //TODO: Don't fill this if "navigation_url_link" is filled.
    navigation_page_link: "/help", //? This is for setting Page Link to the Link Label in Header.

    //TODO: Don't fill this if "navigation_page_link" is filled.
    navigation_url_link: "", //? This is for setting URL Link to the Link Label in Header.

    //! IT IS BOOLEAN
    navigation_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Header.
  },
]



//? This is for adding Link in a dropdown container in Header.
//! IT IS ARRAY
export const HeaderListData = [
  // {
  //   navigation_list_name: "Dropdown Container", //? This is for setting Label for the Dropdown Container that will be showed in Header.

  //   //! IT IS ARRAY
  //   navigation_list_items: [
  //     {
  //       navigation_link_label: "", //? This is for setting Link Label that will be showed in Header.
    
  //       //TODO: Don't fill this if "navigation_url_link" is filled.
  //       navigation_page_link: "", //? This is for setting Page Link to the Link Label in Header.
    
  //       //TODO: Don't fill this if "navigation_page_link" is filled.
  //       navigation_url_link: "", //? This is for setting URL Link to the Link Label in Header.
    
  //       //! IT IS BOOLEAN
  //       navigation_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Header.
  //     },
  //   ],
  // },
]



//? This is for controlling which parts will be visible of Header.
export const HeaderConfig = {
  //! IT IS BOOLEAN
  navigation_brand_logo: "True", //? This is for controlling if Logo will be showed in Header.

  //! IT IS BOOLEAN
  navigation_brand_name: "True", //? This is for controlling if Name will be showed in Header.
}



//? This is for configuring different parts of Footer.
export const FooterConfig = {
  time_saturday: "7AM-10PM", //? This is for setting Opening Hours for Saturday in Footer.
  time_sunday: "7AM-10PM", //? This is for setting Opening Hours for Sunday in Footer.
  time_monday: "7AM-10PM", //? This is for setting Opening Hours for Monday in Footer.
  time_tuesday: "7AM-10PM", //? This is for setting Opening Hours for Tuesday in Footer.
  time_wednesday: "7AM-10PM", //? This is for setting Opening Hours for Wednesday in Footer.
  time_thursday: "7AM-10PM", //? This is for setting Opening Hours for Thursday in Footer.
  time_friday: "7AM-10PM", //? This is for setting Opening Hours for Friday in Footer.
  email_label: "example@gmail.com", //? This is for setting Email Label in Footer.
  email_url_link: "example@gmail.com", //? This is for setting Email Address for Email Label in Footer. Should be same as Email Label in Footer.
  phone_label: "123-456-7890", //? This is for setting Phone Label in Footer.
  phone_url_link: "+1123-456-7890", //? This is for setting Phone Number for Phone Label in Footer. Should be same as Phone Label but can contain country code too.
  location_label: "Burj Khalifa tower", //? This is for setting Location Label in Footer.
  location_url_link: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Google Maps Location Link for Location Label in Footer.
  social_profile_youtube: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting youtube Link in Footer.
  social_profile_facebook: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Facebook Link in Footer.
  social_profile_instagram: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Instagram Link in Footer.
  social_profile_pinterest: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Pinterest Link in Footer.
  social_profile_tiktok: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Tiktok Link in Footer.
  social_profile_twitter: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Twitter Link in Footer.
  social_profile_yelp: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Yelp Link in Footer.
  social_profile_etsy: "https://goo.gl/maps/tXjgXKitJhM6iNny5", //? This is for setting Etsy Link in Footer.
  footer_brand_text: "Owned By", //? This is for setting Text before Footer Label in Footer.
  footer_brand_label: "Tahsin", //? This is for setting Footer Label in Footer.
  footer_brand_link: "", //? This is for setting Footer Link for Footer Label in Footer. This is optional.

  //! IT IS BOOLEAN
  footer_info_part: "yes", //? This is for controlling if Info Part in Footer will be showed. This contains Email, Number and Address.

  //! IT IS BOOLEAN
  //TODO: Only turn it on if something written in "FooterData"
  footer_link_part: "", //? This is for controlling if Link Part in Footer will be showed. This contains multiple links.
  footer_time_part: "yes", //? This is for controlling if Time Part in Footer will be showed. This contains Opening Hours.

  //! IT IS BOOLEAN
  footer_map_part: "yes", //? This is for controlling if Map Part in Footer will be showed. This contains Map Image.
}



//? This is for adding URL And Page Link in Footer.
//TODO: Turn it on inside "footer_link_part"
//! IT IS ARRAY
export const FooterData = [
  // {
  //   footer_label: "Pacito", //? This is for setting Footer Link Label in Footer.
    
  //TODO: Don't fill this if "footer_page_link" is filled.
  //   footer_url_link: "https://www.w3schools.com/css/tryit.asp?filename=trycss_grid_grid-template-rows", //? This is for setting URL Link to the Footer Link Label in Footer.

  //TODO: Don't fill this if "footer_url_link" is filled.
  //   footer_page_link: "", //? This is for setting Page Link to the Footer Link Label in Footer.
  // },
]



//? This is for configuring different parts of Counter Bar.
export const CounterBarConfig = {
  minimum_viewer: "1", //? This is for setting Minimum Viewer Count in Counter Bar.
  maximum_viewer: "7", //? This is for setting Maximum Viewer Count in Counter Bar.
  bar_show_delay: "20000", //? This is for setting Delay Between Each Appearance of Counter Bar in millisecond.
  bar_show_time: "5000", //? This is for setting Visibility Time of Counter Bar in millisecond.
}



//? This is for configuring different parts of Footer Bar.
export const FooterBarConfig = {
  //Coprwriting: Take this quick quiz to see if you qualify for our service
  footer_bar_text: "Take a quiz while waiting :)", //? This is for setting Text in Footer Bar.
  button_label: "Take Quiz", //? This is for setting Button Label in Footer Bar. By default it will open Lead Form.

  //TODO: Don't fill this if "button_url_link" is filled.
//  button_page_link: "", //? This is for setting Page Link to the Button Label in Footer Bar.

  //TODO: Don't fill this if "button_page_link" is filled.
//  button_url_link: "", //? This is for setting URL Link to the Button Label in Footer Bar.

  //! IT IS BOOLEAN
//  button_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Footer Bar.
}



//? This is for configuring Hero Section of Index Page.
export const HeroConfig = {
  //TODO: Don't fill this if "hero_heading_text" is filled.
  //hero_heading_typewriter_text: ["Live Life King Size", "Live Life Queen Size"],
  //hero_heading_typewriter_before_text: "Best lover can",
  //hero_heading_typewriter_after_text: "But no one...",

  //TODO: Don't fill this if "hero_heading_typewriter_text" is filled.
  hero_heading_text: "Tahsin Owner Jibber Jabber", //? This is for setting Heading Text in Hero Section of Index Page.
  hero_text: "This website is currently under construction!", //? This is for setting Sub-Heading Text in Hero Section of Index Page.
  button_label: "Take Quiz", //? This is for setting Button Label in Hero Section. By default it will open Lead Form.

  //TODO: Don't fill this if "button_url_link" is filled.
//  button_page_link: "", //? This is for setting Page Link to the Button Label in Hero Section.

  //TODO: Don't fill this if "button_page_link" is filled.
//  button_url_link: "", //? This is for setting URL Link to the Button Label in Hero Section.

  //! IT IS BOOLEAN
//  button_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Hero Section.
//  link_label: "", //? This is for setting Link Label in Hero Section. By default it will open Lead Form.

  //TODO: Don't fill this if "link_url_link" is filled.
//  link_page_link: "", //? This is for setting Page Link to the Link Label in Hero Section.

  //TODO: Don't fill this if "link_page_link" is filled.
//  link_url_link: "", //? This is for setting URL Link to the Link Label in Hero Section.

  //! IT IS BOOLEAN
//  link_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Hero Section.
}



//? This is for configuring Video Section of Index Page.
export const VideoConfig = {
  section_header: "Any Random Video", //? This is for setting Heading Text in Video Section of Index Page.
//  section_sub_header: "", //? This is for setting Sub-Heading Text in Video Section of Index Page.

  video_url: "https://youtu.be/ig24277LfI0", //? This is for setting Video URL in Video Section.
//  video_thumbnail: "", //? This is for setting Video Thumbnail in Video Section.
//  video_loop: "", //? This is for controlling if Video will be Looped in Video Section.
//  video_mute: "", //? This is for controlling if Video will be Muted in Video Section.
//  video_controls: "", //? This is for controlling if Video Controls will be showed in Video Section.
}



//? This is for configuring About Section of Index Page.
export const AboutConfig = {
  section_header: "Our Story", //? This is for setting Heading Text in About Section of Index Page.
//  section_sub_header: "", //? This is for setting Sub-Heading Text in About Section of Index Page.
  about_text: "Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. This is our Jibber Jabber story. Hope you like it. ", //? This is for setting Text in About Section.

//  about_image: "", //? This is for setting Image in About Section.
//  about_image_height: "427", //? This is for setting Image Height in About Section. This is for SEO.
//  about_image_width: "640", //? This is for setting Image Width in About Section. This is for SEO.
//  about_image_stretch_width: "100%", //? This is for setting Image Stretch Width in About Section.
}



//? This is for configuring FAQ Section of Index Page.
export const FaqConfig = {
  section_header: "Frequently Asked Questions", //? This is for setting Heading Text in FAQ Section of Index Page.
//  section_sub_header: "", //? This is for setting Sub-Heading Text in FAQ Section of Index Page.
}



//? This is for adding Questions And Answers in FAQ Section of Index Page.
//! IT IS ARRAY
export const FaqData = [
  {
    faq_question: "Question 1", //? This is for setting Question in FAQ Section.
    faq_answer: "Answer 1", //? This is for setting Answer in FAQ Section.
  },
  {
    faq_question: "Question 2",
    faq_answer: "Answer 2",
  },
  {
    faq_question: "Question 3",
    faq_answer: "Answer 3",
  },
  {
    faq_question: "Question 4",
    faq_answer: "Answer 4",
  },
]



//? This is for configuring Benefits Section of Index Page.
export const BenefitsConfig = {
  section_header: "Benefits", //? This is for setting Heading Text in Benefits Section of Index Page.
//  section_sub_header: "", //? This is for setting Sub-Heading Text in Benefits Section of Index Page.
}



//? This is for adding Benefits in Benefits Section of Index Page.
//! IT IS ARRAY
export const BenefitsData = [
  {
    benefits_heading: "Question 1", //? This is for setting Heading in Benefits Section.
    benefits_text: "Answer 1", //? This is for setting Text in Benefits Section.
    button_label: "BUY NOW", //? This is for setting Button Label in Benefits Section.
    
    //TODO: Don't fill this if "button_url_link" is filled.
    // button_page_link: "", //? This is for setting Page Link to the Button Label in Benefits Section.

    //TODO: Don't fill this if "button_page_link" is filled.
    // button_url_link: "", //? This is for setting URL Link to the Button Label in Benefits Section.

    //! IT IS BOOLEAN
    // button_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Benefits Section.
    // benefits_image: "", //? This is for setting Image in Benefits Section.
    // benefits_image_height: "427", //? This is for setting Image Height in Benefits Section. This is for SEO.
    // benefits_image_width: "640", //? This is for setting Image Width in Benefits Section. This is for SEO.
    // benefits_image_stretch_width: "100%", //? This is for setting Image Stretch Width in Benefits Section.
  },
  {
    benefits_heading: "Question 2",
    benefits_text: "Answer 2",
    button_label: "BUY NOW",
    button_url_link: "https://goo.gl/maps/tXjgXKitJhM6iNny5",
    button_url_new_tab: "*",
    benefits_image: "",
    benefits_image_height: "427",
    benefits_image_width: "640",
    benefits_image_stretch_width: "100%",
  },
  {
    benefits_heading: "Question 3",
    benefits_text: "Answer 3",
    button_label: "BUY NOW",
    button_url_link: "https://goo.gl/maps/tXjgXKitJhM6iNny5",
    benefits_image: "",
    benefits_image_height: "427",
    benefits_image_width: "640",
    benefits_image_stretch_width: "100%",
  },
]



//? This is for configuring Social Proof Section of Index Page.
export const SocialProofConfig = {
  section_header: "Actual Customer Reviews", //? This is for setting Heading Text in Social Proof Section of Index Page.
//  section_sub_header: "", //? This is for setting Sub-Heading Text in Social Proof Section of Index Page.

  //TODO: Set the number of Slides written in "SocialProofData"
  number_of_slides: "4", //? This is for setting Number Of Slides in Social Proof Section.
  visible_of_slides: "1", //? This is for setting Number Of Visible Slides At Once in Social Proof Section.
  interval_of_slides: "2500", //? This is for setting Delay Between Each Appearance in Social Proof Section.
}



//? This is for adding URL And Page Link in Footer.
//TODO: Set the number of Slides inside "number_of_slides"
//! IT IS ARRAY
export const SocialProofData = [
  {
//  social_proof_heading: "", //? This is for setting Heading in Social Proof Section.
    social_proof_text: "Demo Text That Works 1", //? This is for setting Text in Social Proof Section.
    social_proof_author: "Author 1", //? This is for setting Author in Social Proof Section.
//    social_proof_image: "", //? This is for setting Image in Social Proof Section.
//    social_proof_image_height: "", //? This is for setting Image Height in Social Proof Section. This is for SEO.
//    social_proof_image_width: "", //? This is for setting Image Width in Social Proof Section. This is for SEO.
//    social_proof_image_stretch_width: "", //? This is for setting Image Stretch Width in Social Proof Section.
  },
  {
    social_proof_text: "Demo Text That Works 2",
    social_proof_author: "Author 2",
    social_proof_image: "",
    social_proof_image_height: "",
    social_proof_image_width: "",
    social_proof_image_stretch_width: "",
  },
  {
    social_proof_text: "Demo Text That Works 3",
    social_proof_author: "Author 3",
    social_proof_image: "",
    social_proof_image_height: "",
    social_proof_image_width: "",
    social_proof_image_stretch_width: "",
  },
  {
    social_proof_text: "Demo Text That Works 4",
    social_proof_author: "Author 4",
    social_proof_image: "",
    social_proof_image_height: "",
    social_proof_image_width: "",
    social_proof_image_stretch_width: "",
  },
]



//? This is for configuring Call To Action Section of Index Page.
export const CallToActionConfig = {
  section_header: "Call To Action", //? This is for setting Heading Text in Call To Action Section of Index Page.
  section_sub_header: "", //? This is for setting Sub-Heading Text in Call To Action Section of Index Page.
  button_label: "Take Quiz", //? This is for setting Button Label in Call To Action Section. By default it will open Lead Form.
  
  //TODO: Don't fill this if "button_url_link" is filled.
//  button_page_link: "", //? This is for setting Page Link to the Button Label in Call To Action Section.

  //TODO: Don't fill this if "button_page_link" is filled.
//  button_url_link: "", //? This is for setting URL Link to the Button Label in Call To Action Section.
  
  //! IT IS BOOLEAN
//  button_url_new_tab: "", //? This is for controlling if the URL Link will be opened in a new window in Call To Action Section.
}



//? This is for configuring Lead Form.
export const FormConfig = {
  //TODO: Only turn it on if something written in "FormRadioData"
  //! IT IS BOOLEAN
  form_step_one: "y", //? This is for controlling if First Step in Lead Form will be showed.

  //TODO: Only turn it on if something written in "FormInputData"
  //! IT IS BOOLEAN
  form_step_two: "y", //? This is for controlling if Second Step in Lead Form will be showed.

  //TODO: Only turn it on if something written in "FormInputTextData"
  //! IT IS BOOLEAN
  form_step_three: "y", //? This is for controlling if Third Step in Lead Form will be showed.

  //TODO: Only turn it on if something written in "FormInputEmailData"
  //! IT IS BOOLEAN
  form_step_four: "y", //? This is for controlling if Fourth Step in Lead Form will be showed.

  //! IT IS BOOLEAN
  form_hide_back_button: "", //? This is for controlling if Back Button in Lead Form will be showed.

  //! IT IS BOOLEAN
  form_autocomplete_on: "y", //? This is for controlling if data will be auto completed in Lead Form.

  //! IT IS BOOLEAN
  form_price_formatted: "y", //? This is for controlling if Price will be Formatted in Lead Form.

  //! IT IS BOOLEAN
  form_total_value_formatted: "y", //? This is for controlling if Total Value will be Formatted in Lead Form.
  form_currency_sign: "$", //? This is for setting Currency Sign in Lead Form. Keeping it empty will hide Price from label.
  form_total_value_title: "Total Price", //? This is for setting Total Value in Lead Form. Keeping it empty will hide Total Price from Lead Form.

  form_firebase_link: "https://test-web-app-007-default-rtdb.firebaseio.com/", //? This is for setting Receive Firebase Link in Lead Form.
  form_firebase_auth: "8QLArKEM0HNqxTkoNXZy5DLafwpW0OHe3AK18XdZ", //? This is for setting Receive Firebase Auth Key in Lead Form.

  form_spam_email: "https://formsubmit.co/ajax/secretinspy007@gmail.com", //? This is for setting Spam Receive Email in Lead Form.

  form_email_subject: "Frequently Asked Questions", //? This is for setting Receive Email Subject in Lead Form.
  form_email: "https://formsubmit.co/ajax/lilyntalmers@gmail.com", //? This is for setting Receive Email in Lead Form. Emaxple: https://formsubmit.co/ajax/lilyntalmers@gmail.com

  form_sheet_link: "", //? This is for setting Receive Sheet Link in Lead Form. Emaxple: https://api.sheetson.com/v2/sheets/TieForm
  form_authorization: "", //? This is for setting Receive Sheet Auth Key in Lead Form. Emaxple: Bearer YO5YlOLu4Xfujbt3os3KA843J3bjvHVGrmvZ1Pq-zk3IYT8zfgRm3osFIwc
  form_x_s_s_id: "", //? This is for setting Receive Sheet ID in Lead Form. Emaxple: 1rb0iSPn00Jd_TwHIl6jCfBfMRcAqE1dLsyeydQvW9_E
}



//? This is for adding Radio Questions Part in Lead Form.
//TODO: Turn it on inside "form_step_one"
//! IT IS ARRAY
export const FormRadioData = [
  // {
  //   form_radio_category: "", //? This is for setting Question in Radio Input Part of Lead Form.
  //   form_radio_help_image: "", //? This is for setting Help Image in Radio Input Part of Lead Form. It will be inside a dropdown under the input.
  //   form_radio_help_text: "", //? This is for setting Help Text in Radio Input Part of Lead Form. It will be under the Question.
    
  //! IT IS ARRAY
  //   form_radio_options: [
  //     {
  //       form_radio_sub_category: "", //? This is for setting Option for the Question in Radio Input Part of Lead Form.
  //       form_radio_price: , //? This is for setting Price for the Option in Radio Input Part of Lead Form. And there should be NO quotation marks (" ").
  //       form_radio_image: "", //? This is for setting Image for the Option in Radio Input Part of Lead Form.
  //     },
  //   ]
  // },
  {
    form_radio_category: "What type of Beaf do you like?",
    form_radio_help_image: "https://media.giphy.com/media/l4pLXMFYQmTM2se7m/giphy.gif",
    form_radio_help_text: "This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text",
    form_radio_options: [
      {
        form_radio_sub_category: "Cheap Beaf",
        form_radio_price: 1000,
        form_radio_hide_price: "",
        form_radio_image: ""
      },
      {
        form_radio_sub_category: "Expensive Beaf",
        form_radio_price: 2000,
        form_radio_hide_price: "",
        form_radio_image: ""
      }
    ]
  },
  {
    form_radio_category: "Cheese",
    form_radio_help_image: "https://media.giphy.com/media/l4pLXMFYQmTM2se7m/giphy.gif",
    form_radio_help_text: "This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text",
    form_radio_options: [
      {
        form_radio_sub_category: "Cheap Cheese",
        form_radio_price: 1000,
        form_radio_hide_price: "",
        form_radio_image: "https://www.xul.fr/en/css/images/20.jpg"
      },
      {
        form_radio_sub_category: "Expensive Cheese",
        form_radio_price: 2000,
        form_radio_hide_price: "",
        form_radio_image: "https://www.xul.fr/en/css/images/20.jpg"
      }
    ]
  },
  {
    form_radio_category: "Bread",
    form_radio_help_image: "",
    form_radio_help_text: "",
    form_radio_options: [
      {
        form_radio_sub_category: "Cheap Bread",
        form_radio_price: 400,
        form_radio_hide_price: "",
        form_radio_image: "https://www.xul.fr/en/css/images/20.jpg"
      },
      {
        form_radio_sub_category: "Expensive Bread",
        form_radio_price: 2000,
        form_radio_hide_price: "",
        form_radio_image: "https://www.xul.fr/en/css/images/20.jpg"
      }
    ]
  }
]



//? This is for adding Text Questions Part in Lead Form.
//TODO: Turn it on inside "form_step_two"
//! IT IS ARRAY
export const FormInputData = [
  // {
  //   form_field_name: "", //? This is for setting Question in Text Input Part of Lead Form.
  //   form_input_price: , //? This is for setting Price in Text Input Part of Lead Form. And there should be NO quotation marks (" ").

  //! IT IS BOOLEAN
  //   form_input_required: "", //? This is for controlling if this Quetion in Text Input Part of Lead Form will be required to fill.
  //   form_input_help_image: "", //? This is for setting Help Image in Text Input Part of Lead Form. It will be inside a dropdown under the input.
  //   form_input_help_text: "", //? This is for setting Help Text in Text Input Part of Lead Form. It will be under the Question.
  // },
  {
    form_field_name: "City Name",
    form_input_price: -10,
    form_input_hide_price: "",
    form_input_required: "",
    form_input_help_image: "",
    form_input_help_text: "",
  },
  {
    form_field_name: "Zip Code",
    form_input_required: "",
    form_input_help_image: "https://media.giphy.com/media/l4pLXMFYQmTM2se7m/giphy.gif",
    form_input_help_text: "This a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text",
  },
]



//? This is for adding Paragraph Questions Part in Lead Form.
//TODO: Turn it on inside "form_step_three"
//! IT IS ARRAY
export const FormInputTextData = [
  // {
  //   form_field_name: "", //? This is for setting Question in Paragraph Input Part of Lead Form.
  //   form_input_price: , //? This is for setting Price in Paragraph Input Part of Lead Form. And there should be NO quotation marks (" ").

  //! IT IS BOOLEAN
  //   form_input_required: "", //? This is for controlling if this Quetion in Paragraph Input Part of Lead Form will be required to fill.
  //   form_input_help_image: "", //? This is for setting Help Image in Paragraph Input Part of Lead Form. It will be inside a dropdown under the input.
  //   form_input_help_text: "", //? This is for setting Help Text in Paragraph Input Part of Lead Form. It will be under the Question.
  // },
  {
    form_field_name: "Secret Message",
    form_input_price: 50,
    form_input_hide_price: "",
    form_input_required: "",
    form_input_help_image: "",
    form_input_help_text: "",
  },
  {
    form_field_name: "Hyper Secret Message",
    form_input_required: "",
    form_input_help_image: "https://media.giphy.com/media/l4pLXMFYQmTM2se7m/giphy.gif",
    form_input_help_text: "This a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text This is a demo descriptive text",
  },
]



//? This is for configuring Email Asking Part in Lead Form.
//TODO: Turn it on inside "form_step_four"
export const FormInputEmailData = {
  //! IT IS BOOLEAN
  form_input_required: "yes", //? This is for controlling if Email in Email Input Part of Lead Form will be required to fill.
  form_input_help_text: "We will only use this email to send you valuable information and won't send a single spam email because your email is already full of them and you don't need one more!", //? This is for setting Help Text in Email Input Part of Lead Form. It will be under the input.
}